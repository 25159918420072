import tw, { styled } from 'twin.macro';
import { Footer } from '../../components/footer';
import Layout from '../../components/Layout';
import { Navigation } from '../../components/Navigation';

const Container = styled.div`
  ${tw`container w-11/12 md:w-3/4 mx-auto mt-10 py-2 px-3 pr-2 md:py-12 md:px-14 md:pr-12 lg:py-20 lg:px-24 lg:pr-20 h-3/4 bg-gray-100 rounded-xl overflow-scroll overflow-x-hidden`}
`;

function KycPolicy() {
  return (
    <Layout tw="font-body flex flex-col overflow-x-hidden md:overflow-x-visible">
      <Navigation />
      <h2 tw="flex self-center mt-32 text-black text-center text-3xl md:text-6xl font-bold">
        KYC Policy
      </h2>
      <Container className="example">
        <p tw="leading-7 text-sm md:text-base xl:text-lg">
          <h1 tw="font-semibold pb-3 text-xl uppercase">AML AND KYC POLICY</h1>
          <p tw="pb-2">INTRODUCTION</p>
          <p tw="pb-2">
            Bidda s.p z.o.o (hereinafter – “Bidda”) Anti-Money Laundering and
            KnowYour Customer Policy (hereinafter – “AML and KYC Policy”) is
            designated to prevent and mitigate possible risks of Bidda being
            involved in any kind of illegal activity.Both international and
            local regulations require Bidda to implement effective internal
            procedures and mechanisms to prevent money laundering, terrorist
            financing, drug and human trafficking, proliferation of weapons of
            mass destruction, corruption and bribery and to take action in case
            of any form of suspicious activity from its Users.This AML and KYC
            Policy applies to both natural and legal persons. Legal entities
            joining the Bidda ICO, which is defined as making a Bidda token
            purchase in a specific method stated in the clause 4 of this AML and
            KYC Policy will receive purchased tokens to their ERC-20 address
            after required AML and KYC procedures that include providing
            specific documents.
          </p>
          <p>
            AML and KYC Policy covers the following matters:
            <br />
            1. Verification procedures.
            <br />
            1.1. Basis
            <br />
            1.2. Identity verification
            <br />
            1.3. Anti-money laundering procedure
            <br />
            1.4. Authenticity verification
            <br />
            1.5. Right to report
            <br />
            1.6. Continuous basis of information verification
            <br />
            1.7. Transaction proceed refusal
            <br />
            1.8. Payment cancellation
            <br />
            2. Compliance Officer.
            <br />
            2.1. Definition
            <br />
            2.2. Duties
            <br />
            2.3. Law enforcement
            <br />
            3. Monitoring Transactions.
            <br />
            3.1. Data analysis
            <br />
            3.2. Suspicious transactions reporting
            <br />
            4. Payment methods
            <br />
            4.1. Metamask, payment cards
            <br />
            4.2. Payment cards usage control
            <br />
            4.3. Metamask transfer control
            <br />
            4.4. Address analysis
            <br />
            4.5. Liability
            <br />
            4.6. Due diligence
            <br />
            5. Risk Assessment.
            <br />
            6. AML and KYC Policy
            <br />
            6.1. Acceptance
            <br />
            6.2. Privacy Policy
            <br />‍<br />
          </p>
          <p>
            1. <strong>Verification procedures</strong>
          </p>
          <p tw="pb-2">
            1.1. Basis
            <br />
            One of the international standards for the prevention of illegal
            activities is Know YourCustomer (“KYC”). According to the KYC, Bidda
            establishes its own verification procedures within the framework of
            anti-money laundering standards and Know Your Customer.
          </p>
          <p tw="pb-2">
            1.2. Identity verification
            <br />
            Bidda’s identity verification procedure requires the User to provide
            reliable independent sources of documents, data or information (e.g.
            ID card, international passport, bank statement, utility bills)
            every time the User is requested to provide certain information. For
            AML and KYC Policy purposes, Bidda hereby reserves the right to
            collect User information.
          </p>
          <p tw="pb-2">
            1.3. Anti-money laundering procedure
            <br />
            Bidda’s anti-money laundering procedure requires the User to provide
            information about the source of the funds the User uses to purchase
            Bidda tokens every time the User is requested to provide certain
            information. Bidda is not responsible for any errors or omissions in
            the information provided by the User.
          </p>
          <p tw="pb-2">
            1.4. Authenticity verification
            <br />
            Bidda shall take steps to verify the authenticity of documents and
            information provided by Users, use all legal methods to double-check
            identification information and hereby reserves the right to
            investigate the actions of certain Users that have been found risky
            or suspicious.
          </p>
          <p tw="pb-2">
            1.5. Right to report
            <br />
            We may therefore report our suspicions regarding any activity of the
            users to local regulators, enforcement bodies and other compliance
            bodies. Those kind of actions have a goal of ensuring that the
            capital being used on our platform has a legitimate origin and
            create a safeguard in case of individuals or groups wanting to
            allocate their money from illegal activities on our platform.
          </p>
          <p tw="pb-2">
            1.6. Continuous basis of information verification
            <br />
            Bidda hereby reserves the right to verify the User’s identity on a
            continuous basis, especially when the verification information has
            been changed or the User’s activity has been deemed suspicious, even
            if they have already undergone identity verification. User
            verification information shall be collected, stored, transferred and
            protected strictly in accordance with Bidda’s Data Protection Policy
            and the relevant rules. Following the verification of User’s
            identity, Bidda disclaims potential legal liability in situations
            where its Services are used for illegal activities.
          </p>
          <p>
            1.7. Transaction proceed refusal
            <br />
            Bidda may refuse to proceed the transaction of transferring the
            purchased tokens to the User if the User refuses to provide any
            demanded information. Bidda may refuse to issue tokens to the User
            if:
          </p>
          <p tw="pb-2">
            a. The User does not provide any demanded information to Bidda and
            enables to complete any diligence procedures on the User;
            <br />
            b. The User have been previously identified as engaged in suspicious
            activity;
            <br />
            c. The User is the citizen of a country considered to be exposed to
            a high risk of money laundering or financing terrorism;
            <br />
            d. The User is listed on a sanctions list in any of the
            jurisdictions to which Bidda may transfer purchased tokens, or Bidda
            otherwise identifies the User as exposed to a high risk of money
            laundering or financing terrorism.
          </p>
          <p tw="pb-2">
            1.8. Payment cancellation.
            <br />
            The User cannot cancel a request to purchase the tokens. Bidda may
            cancel the User’s purchase of the tokens if it is impossible to
            verify User’s identity or conduct any other procedures that Bidda is
            required to conduct. Bidda may not refund User any amount until the
            User has provided any demanded information required by law.
          </p>
          <p>
            2. <strong>Compliance Officer</strong>
          </p>
          <p tw="pb-2">
            2.1 Definition
            <br />
            The Compliance Officer is the person, duly authorized by Bidda,
            whose duty is to ensure the effective implementation and enforcement
            of the AML and KYCPolicy.
          </p>
          <p>
            2.2 Duties
            <br />
            It is the Compliance Officer’s responsibility to supervise all
            aspects of Bidda Coin’s anti-money laundering and counter-terrorist
            financing, including but not limited to:
          </p>
          <p tw="pb-2">
            a. Collecting User’s identification information;
            <br />
            b. Establishing and updating internal policies and procedures for
            the completion, review, submission and retention of all reports and
            records required under the applicable laws and regulations;
            <br />
            c. Monitoring transactions and investigating any significant
            deviations from normal activity;
            <br />
            d. Implementing a record management system for appropriate storage
            and retrieval of documents, files, forms and logs;
            <br />
            e. Updating risk assessment regularly;
            <br />
            f. Providing law enforcement with information as required under the
            applicable laws and regulations.
          </p>
          <p tw="pb-2">
            2.3 Law enforcement
            <br />
            The Compliance Officer is entitled to interact with law enforcement,
            which are involved in prevention of money laundering, terrorist
            financing and other illegal activity.
          </p>
          <p tw="pb-2">
            2.4 Contact with the clients and authorities
            <br />
            The Compliance Officer is obliged to communicate effectively with
            the clients and authorities having any complaints or questions that
            are being addressed to him regarding the Bidda AML and KYC Policy as
            also its implementation in order to ensure the transparency and
            credibility of the platform. The address to contact the Compliance
            Officer is hello@bidda.io. Any kind of correspondence sent to the
            Compliance Officer will be addressed by him within the time frame of
            5working days.
          </p>
          <p>
            3. <strong>Monitoring transactions</strong>
          </p>
          <p tw="pb-2">
            3.1 Data analysis
            <br />
            User verification is carried out not only by checking their
            identity, but also by analyzing transaction models. Bidda therefore
            relies on data analysis as a tool for assessing risk and identifying
            suspicions. Bidda carries out many regulatory compliance tasks,
            including data collection, filtering and record keeping.
          </p>
          <p tw="pb-2">
            3.2 Suspicious transactions reporting
            <br />
            With regard to the AML and KYC Policy, Bidda will monitor all
            transactions, and it reserves the right to ensure that transactions
            of suspicious nature are reported to the proper law enforcement
            through the Compliance Officer and request the User to provide any
            additional information and documents in case of suspicious
            transactions. Compliance Officer will monitor User’s transactions on
            a day-to-day basis in order to define whether such transactions are
            to be reported and treated as suspicious or are to be treated as
            bona fide.
            <br />
          </p>
          <p>
            4. <strong>Payment methods</strong>
          </p>
          <p tw="pb-2">
            4.1 Metamask, payment card
            <br />
            The User may purchase Bidda tokens by linking the Metamask wallet
            and managing the Ethereum deposit, transferring any cryptocurrency
            or by card or wire transfer. To carry out a transaction in a chosen
            way, the User must follow the relevant instructions on the Bidda
            site.
          </p>
          <p tw="pb-2">
            4.2 Payment cards usage control
            <br />
            Bidda may refuse to complete or block, cancel or reverse a
            transaction of the Bidda token purchase made with payment cards
            usage if the User is suspected of money laundering, terrorist
            financing, fraud, any other financial crime, or any other illegal
            action. Bidda is under no obligation to disclose the details of its
            credit card risk management and security procedures to the User.
          </p>
          <p tw="pb-2">
            4.3 Metamask transfer control
            <br />
            Bidda may refuse to complete or block, cancel or reverse a
            transaction of the Bidda token purchase made with Metamask (MetaMask
            c/o ConsenSys, 49 Bogart Street, Brooklyn NY 11206) usage if the
            User is suspected of money laundering, terrorist financing, fraud,
            any other financial crime, or any other illegal action.
          </p>
          <p tw="pb-2">
            4.4 Address analysis
            <br />
            Bidda may analyze the cryptocurrency addresses against common black
            lists and known security discrepancies, to ensure that the funds do
            not come from illicit sources, and there are no signs of money
            laundering (location, layers, integration),either manually or using
            a third-party service.
          </p>
          <p tw="pb-2">
            4.5 Liability
            <br />
            Bidda has no control over, or liability for, the delivery, quality,
            safety and legality or any other aspect of any goods or services
            received or purchased from Metamask.
          </p>
          <p>
            5. <strong>Risk assessment</strong>
          </p>
          <p tw="pb-2">
            Bidda, in accordance with international requirements takes a
            risk-based approach to combating money laundering and the financing
            of the terrorism. By applying a risk-based approach, Bidda can
            ensure that measures to prevent or mitigate money laundering and
            terrorist financing are proportionate with the identified risks.
            This makes it possible for resources to be allocated in the most
            efficient way. The principle is to allocate resources corresponding
            to priorities so that the highest risks are given the closest
            attention.
          </p>
          <p>
            6. <strong>AML and KYC Policy</strong>
          </p>
          <p tw="pb-2">
            6.1 Acceptance
            <br />
            By joining the Bidda ICO, which is defined as making a Bidda token
            purchase in a specific method stated in the clause 4 of this AML and
            KYC Policy, the User accepts the terms of this AML and KYC Policy
            and hereby agrees to have read, understood and accepted the Terms of
            Use and Data Protection Policy available at:{' '}
            <a href="https://www.Bidda.io/terms-conditions" tw="underline">
              https://www.Bidda.io/terms-conditions
            </a>{' '}
            In addition, when using certain services, the User may be subject to
            additional agreements applicable to such services. The User must
            stop using the services and the website if they do not agree with
            the AML and KYC Policy and the Terms of Use and Data Protection
            Policy.
          </p>
          <p>
            6.2 Privacy policy
            <br />
            User’s identification information will be collected, stored, shared
            and protected strictly in accordance with the Bidda’s Privacy Policy
            and related regulations.
          </p>
        </p>
      </Container>
      <div tw="bg-dark mt-20 px-5">
        <Footer />
      </div>
    </Layout>
  );
}

export default KycPolicy;
